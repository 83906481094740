.online__workout-planner {
  width: 100%;
  position: relative;
  height: 530px;
  min-height: 530px;
  background-color: transparent;
  padding: 60px 50px 0 50px;
}

.workout-planner__background {
  width: 100%;
  height: 1060px;
  z-index: -50;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;

  transition-property: transform,opacity;
  transition-timing-function: cubic-bezier(0,.33,.07,1.03);
  transition-duration: 1s;
}

.workout-planner__title {
  text-align: center;
}

.workout-planner__desc {
  text-align: center;
  color: #fff;
  font-family: "Futura",Sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.online__workout-planner img {
  width: 570px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 170px;
}

@media(max-width: 768px) {
  .online__workout-planner {
    height: 350px;
    min-height: 350px;
  }


.online__workout-planner img {
  width: 315px;
}

}