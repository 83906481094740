.embla {
  margin: auto;
  --online-slide-height: 19rem;
  --online-slide-spacing: 2rem;
  --online-slide-size: 30%;
  --online-mobile-slide-size: calc((100vw - 110px) + var(--online-slide-spacing));
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--online-slide-spacing) * -1);
}

.online-embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--online-slide-size);
  min-width: 0;
  padding-left: var(--online-slide-spacing);
  border: none;
}

.online-embla__slide__card {
  box-shadow: inset 0 0 0 0.2rem var(--online-detail-medium-contrast);
  border-radius: 16px;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: var(--online-slide-height);
  user-select: none;
  border: none;
  height: 450px;
  padding: 20px;
}

.online-embla__slide__card img{
  border-radius: 16px;
}

.embla__type-name {
  font-size: 30px;
  color: black;
  font-weight: 900;
  line-height: 30px;
}

.embla__type-image {
  width: 100%;
  border-radius: 16px;
  height: 310px;
  min-height: 310px;
  object-fit: cover;
  margin-bottom: 10px;
}

.online-embla__type-description {
  padding: 20px;
  display: flex ;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-color: #FFFFFFB0;
  height: 120px;
  border-radius: 10px 10px 0px 0px;
  font-stretch: ultra-condensed;
  width: calc(100% - 20px);
  line-height: 21px;
  font-weight: 500;
  max-height: 105px;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  -webkit-line-clamp: 4;    
  -webkit-box-orient: vertical; 
  white-space: normal;   
}

.type-description__title {
  font-family: "Futura",Sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1em;
  color: #1D1D1B;
}

.type-description__videos {
  font-family: "Futura",Sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  text-transform: capitalize;
  line-height: 1em;
}

.type-description__btn {
  font-family: "Commissioner",Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  fill: #1D1D1B;
  color:#1D1D1B;
  background-color: #1D1D1B;
  transition-duration: .3s;
  border-style: none;
  padding: 0;
}

.type-description__btn:hover {
  color: var(--primary);
}

.online-embla__controls {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin-bottom: 110px;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  height: 60px;
  display: flex;
  justify-content: center;
}

.online-embla__buttons {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  padding: 0 80px;
  margin-bottom: 110px;
  align-items: center;
}
.online-embla__button {
  -webkit-tap-highlight-color: rgba(var(--online-text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--online-detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--online-text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--online-detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.online-embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.online-embla__dot {
  -webkit-tap-highlight-color: #1D1D1B;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(29, 29, 27, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 12px;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.online-embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--online-detail-medium-contrast);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

.online-embla__dot--selected {
  -webkit-tap-highlight-color: #1D1D1B;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(29, 29, 27, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 12px;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.online-embla__dot--selected {
  background-color: rgba(29, 29, 27, 1);
 
}

.class-types-cta__container {
  padding-right: 85px;
}

@media(max-width: 768px) {
  .online-embla__slide {
    flex: 0 0 45%;
  }

  .online-embla__buttons {
    margin-bottom: 30px;
  }

  .online-embla__controls {
    margin-bottom: 30px;
  }

 
}

@media(max-width: 450px) {

  .online-embla__slide {
    flex: 0 0 var(--online-mobile-slide-size);
  }

  .embla__controls {
    display: flex !important;
    margin-bottom: 1.8rem;
    padding: 0;
  }

  .embla__buttons {
    display: none !important;
  }

  .embla__dots {
    width: 100%;
    justify-content: center !important;
  }


  .class-types-cta__container {
    padding-right: 0;
  }

  .online-embla__buttons {
    bottom: 0;
    margin-bottom: 0;
   padding: 0 30px;
  }

  .online-embla__controls {
    bottom: 0;
    margin-bottom: 0;
  }
}
