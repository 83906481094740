.online__banner {
  height: 550px;
  max-width: 1100px;
  margin: 0 auto;
}

.banner__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 40px;
}

.content__tbm-name {
  text-align: left;
  width: 100%;
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  font-style: oblique;
}


.online__banner .content__title {
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
  font-size: 50px;
  font-weight: 900 !important;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -1px;
}
.content_description {
  width: 100%;
  max-width: 100%;
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
  font-size: 19px;
  font-weight: 400 !important;
  line-height: 1.3em;
}

.content__button {
  padding: 15px 25px;
  line-height: 15px;
  font-size: 15px;
  margin-top: 20px;
  font-family: "Commissioner",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  text-decoration: none;
  fill: var(--e-global-color-text);
  color: var(--dark-secondary);
  background-color: #A4305500;
  transition-duration: .3s;
  border-style: solid;
  border-radius: 32px;
  border-width: 1px;
  height: 47px;
  border-color: var(--dark-secondary);
}

.banner__image-container {
  
  width: 550px;
  min-width: 550px;
  height: 470px;
  position: relative;
}

.online__banner img {
  width: 100%;
  height: 100%;
  border-radius: 20px;

  object-fit: cover;
}

.image-overlay {
  background-color: #67655d;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 20px;
}

.image-container__cardio {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 60px;
  margin-right: 25px;
  width: max-content;
  height: max-content;
  color: white;
  font-family: "Futura Std Condensed",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
}

.image-container__barre {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 200px;
  margin-left: 25px;
  width: 130px;
  height: max-content;
  color: white;
  font-family: "Futura Std Condensed",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
}



.image-container__fuerza {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 60px;
  margin-right: 25px;
  width: max-content;
  height: max-content;
  color: white;
  font-family: "Futura Std Condensed",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
}

@media(max-width: 1024px) {

  .online__banner {
    padding: 0 30px;
  }

  .banner__image-container {
    width: 100%;
    min-width: unset;
  }

  .banner__content {
    width: 335px;
  }
}

@media(max-width: 575px) {

  .banner__image-container {
    width: 100%;
    min-width: 0;
    height: 226px;
  }


  .image-container__barre,
  .image-container__cardio,
  .image-container__fuerza {
    font-size: 20px;
  }

  .image-container__cardio {
    margin-top: 8px;
  }

  .image-container__barre {
    margin-top: 90px;
    margin-left: 24px;
  }

  .image-container__fuerza {
    margin-bottom: 20px;
  }

  .online__banner {
    flex-direction: column;
    padding: 0 30px;
  }
  .banner__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    align-items: center;
  }
  .banner__content {
    order: 2;
  }

  .content__tbm-name {
    text-align: center;
  }

  .online__banner .content__title {
    font-size: 32px !important;
    text-align: center;
    margin-top: 10px;
    text-align: center;
  }

  .content_description {
    font-size: 16px;
    text-align: center;
    width: 297px;
  }

  .content__button {
    padding: 15px 25px;
    line-height: 1;
  }
}

@keyframes imageFadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.image-animation {
  animation: imageFadeIn .75s;
}

