.rewards-hero {
  background-color: #F0F0F0;
  height: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
  position: relative;
}

.hero__title {
  font-family: 'Futura Std', sans-serif;

  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  line-height: 31.68px;
  text-align: center;
  color: #2F2F2B;
}

.hero__tbm {
  font-family: 'Futura Std', sans-serif;

  font-size: 130px;
  font-weight: 750;
  line-height: 50.4px;
  letter-spacing: -6.047618865966797px;
  text-align: center;
  color: white;
  margin-top: 30px;
  
}

.rewards-hero img {
  width: 900px;
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  /* margin-bottom: 80px; */
}

@media(max-width: 767px) {
  .rewards-hero {
    padding-top: 100px;
    height: 675px;
  }

  .hero__tbm {
    font-size: 50px;
    letter-spacing: 0;
    background-image: linear-gradient(180deg, rgba(150, 138, 248, 0.8) 65%, #CEDAF0 95%) !important;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }

  .rewards-hero img {
    width: 100%;

  }
}