

#post-input {
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.gothic-font {
    font-family: "Didact Gothic", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

#accordionExample .accordion-button:not(.collapsed) {
    box-shadow: none !important;
}
#accordionExample .accordion-collapse,
.accordion-item .accordion-opened {
    background-color:  #EEEEEE !important;
}

#accordionExample .accordion-collapse {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#accordionExample .accordion-button,
#accordionExample .accordion-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
}

.accordion-closed {
    background-color:  #F9F9F9 !important;
}

.monterrey-degraded-btn {
    background: rgb(149,137,247);
    background: linear-gradient(90deg, rgba(149,137,247,1) 0%, rgba(223,104,102,1) 100%);
}

.rewards-gradient { 
    background: rgb(149,137,247);
    background: linear-gradient(90deg, #9589F7 0%, #D1DFEF 100%);
}

.w-max-content {
    width: max-content;
}

.h-max-content {
    height: max-content;
}

.wh-max-content {
    width: max-content;
    height: max-content;
}

.main-panel #posts {
    height: calc(100vh - 200px);
}

.post-actions {
    position: absolute;
    width: 150px;
    right: 75px;
    bottom: 15px;
    z-index: 10;
}

.streak-week {
    min-width: 1200px;
}

.streak-week-container {
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
}

#posts-content {
    overflow-y: scroll;
    overflow-x: hidden;
}

.emoji-picker-wrapper {
    position: absolute;
    left: 100%;
}

.emoji-picker-wrapper button {
    position: absolute;
    top: -575px;
    right: -5px;
}

aside.EmojiPickerReact.epr-main {
    position: absolute !important;
    top: -535px;
    right: -5px;
}

.epr-emoji-category-content {
    margin-top: 600px !important;
}

.epr-category-nav {
    display: none !important;
}