.milestone-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 900px;
    width: auto;
}

.milestone-user-name {
    position: absolute;
    bottom: 300px;
}

.congrats {
    position: absolute;
    top: 65px;
}

.btn-share {
    position: absolute;
    bottom: 80px;
}

@media (max-width: 767px) {
    .milestone-container {
        height: 750px;
    }
    .milestone-user-name {
        bottom: 250px;
    }
    
    .btn-share {
        bottom: 65px;
    }
}