#onlineHero {
  background: url('../../assets/video/home_intro.mp4') no-repeat center center;
}

#onlineHero video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  background-size: cover;
  background-position: center;
}

.online-hero-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 600px;
  width: 100%;
  z-index: -99;
  background-color: transparent;
  opacity: .5;
  background-image: radial-gradient(at top right,var(--primary) 0%,var(--blue) 100%);
}

.online-hero__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  padding: 0 20px;
}

.online-hero__title {
  width: 575px;
  text-align: center;
  color: white;
  font-family: "Futura",Sans-serif;
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -1px;
}

.online-hero__desc {
  width: var(--container-widget-width,52%);
  max-width: 52%;
  --container-widget-width: 52%;
  --container-widget-flex-grow: 0;
  text-align: center;
  color: #fff;
  font-family: "Futura",Sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.content__ctas {
  width: 540px;
}

.online-hero-cta {
  font-family: "Commissioner",Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  fill: white;
  color: white;
  background-color: #A4305500;
  transition-duration: .3s;
  border-radius: 32px;
  padding: 15px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
}

.online-hero-cta:hover {
  background-color: #A43055;
  color: white;
}

@media(max-width: 768px) {
  #onlineHero video {
    max-height: 485px;
  }
  .online-hero-overlay {
    height: 485px;
  }

  .online-hero__content {
    height: 485px;
  }
  .online-hero__title {
    font-size: 25px;
    max-width: 296px;
  }

  .online-hero__desc {
    font-size: 18px;
    min-width: 295px;
  }

  .content__ctas .online-hero-cta{
    height: 47px;
    font-size: 15px;
    line-height: 1;
  }

}

@media(max-width: 575px) {

  .content__ctas {
    height: 120px;
    flex-direction: column;
    width: 100%;
  }

  .content__ctas .online-hero-cta{
    width: 100%;
    height: 47px;
    font-size: 15px;
    line-height: 1;
  }
}