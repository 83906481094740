.rewards-rules {
  padding: 50px auto;
  background-color: #f9f9f9;
}

.rewards-rules .accordion {
  border: none !important;
}

.rewards-rules .accordion-item {
  background-color: #f2f2f2;
  box-shadow: none !important;
}

.rewards-rules .accordion-item .item-title {
  font-family: "Futura Std", sans-serif;
  font-size: 20px;
  color: #1d1d1b;
  font-weight: 750;
  line-height: 32px;
  text-align: left;
}

.rewards-rules .accordion-item .accordion-header,
.rewards-rules .accordion-item .accordion-body,
.rewards-rules .accordion .accordion-item .accordion-button {
  background-color: transparent !important;
}

.rewards-rules .accordion .accordion-item .accordion-button {
  border-radius: 9px;
}

.rules-title {
  font-family: "Futura Std", sans-serif;
  font-size: 45px;
  font-style: italic;
  font-weight: 700;
  line-height: 54px;
  text-align: left;
  color: #2f2f2b;
}

.rules-desc {
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #666666;
}

.rewards-rules .card {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  height: 600px;
  background-color: #f2f2f2;
  padding-top: 55px;
  padding-right: 80px;
  padding-bottom: 90px;
  padding-left: 37px;
  border-radius: 8px;
  border: none;
}

.rules-card__image {
  width: 500px;
}

.rules-card__content {
  width: 520px;
}

.rules-card__title {
  width: 360px;
  font-family: "Futura Std", sans-serif;
  font-size: 35px;
  font-style: italic;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: #2f2f2b;
}

.rules-card__description {
  font-family: "Futura Std", sans-serif;

  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
  text-align: left;
}

.rules-btn {
  width: 230px;
  height: 50px;
  color: white;
  font-family: "Futura Std", sans-serif;
  font-size: 19.05px;
  border-radius: 40px;
  border: none;
  font-weight: 750;
  line-height: 24px;
  text-align: center;
}

.rules-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  border-top: 1px solid #666666;
  padding-top: 42px;
}

.rules-bottom__logo {
  margin-bottom: 42px;
}

.rewards-footer__link {
  font-size: 18px;
  text-decoration: none;
  color: #666666;
  font-weight: 400;
}

.max-width-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

#collapseRulesOnSite .onsite-rules {
  background-color: #f2f2f2 !important;
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  height: 400px;
  color: #666666;
  text-decoration-skip-ink: none;
}

#collapseRulesOnline .online-rules {
  background-color: #f2f2f2 !important;
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
  height: 400px;
  text-decoration-skip-ink: none;
}

@media (max-width: 767px) {
  .rules_col {
    padding: 0 !important;
  }
  .rewards-rules {
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: #f9f9f9;
  }

  .rules-title {
    font-size: 30px;
    line-height: 36px;
  }

  .rules__desc-image {
    width: 100%;
  }

  #rulesOnSite {
    padding: 0;
  }

  #rulesOnline {
    padding: 0;
  }

  #collapseRulesOnSite .onsite-rules {
    height: 530px;
  }

  #collapseRulesOnline .online-rules {
    height: 500px;
  }

  .rewards-rules .rules-banner {
    display: flex;
    height: max-content;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .rules-card__title {
    width: 100%;
    margin-top: 20px;
    font-size: 27px;
    line-height: 36px;
  }

  .rules-card__image {
    width: 100%;
  }

  .rules-card__content {
    width: 100%;
  }
}
