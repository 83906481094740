.online__features {
  height: 970px;
  padding-top: 230px;
  padding-bottom: 80px;
  padding-left: 125px;
  padding-right: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.features__content h2 {
  width: 434px;
}

.features__content__feature {
  max-width: 550px;
  padding-bottom: 10px;
  margin-top: 10px;
  font-family: "Futura",Sans-serif;
  font-size: 18px !important;
  font-style: normal;
}

.content__feature-border {
  border-bottom: 1px solid #e5e5e5;
}

.features__content__feature .feature__desc {
  font-family: "Futura",Sans-serif;
  font-size: 18px !important;
  font-style: normal;
}

.features__content--btn {
  font-family: "Commissioner",Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  fill: var(--dark-secondary);
  padding: 15px 20px !important;
  color: var(--dark-secondary);
  background-color: #A4305500;
  transition-duration: .3s;
  font-size: 15px;
  line-height: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--dark-secondary);
  border-radius: 32px;
  height: 47px;
  margin-top: 30px;
}

.features__content--btn:hover {
  background-color: #A43055;
  color: white;
  border-color: white;
}

@media(max-width: 1024px) {
.online__features {
  padding: 0 30px;
  padding-top: 150px;
}

.features__content {
  width: 340px;
}

.features__content h2 {
  width: 100%;
}
}

@media(max-width: 600px) {
  .online__features {
    padding: 0 30px;
    padding-top: 150px;
    flex-direction: column;
    justify-content: flex-start;
    height: 1230px;
  }

  .online__features .features__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .online__features .features__content h2 {
    width: 100%;
    font-size: 28px;
  }

  .online__features .feature__desc {
    font-size: 16px !important;
    line-height: 1.3em;
  }


}