.rewards-brands {
  height: 410px;
  padding: 30px 60px;
}

.brands-title {
  font-family: 'Futura Std', sans-serif;
  font-size: 45px;
  font-weight: 750;
  line-height: 54px;
  text-align: center;
  color: #3A3A33

}

.brands-desc {
  font-family: 'Futura Std', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  
}

.brand-card {
  width: 260px;
  padding: 0 !important;
}

.brand-btn {
  color: #A9A9A9;
  border: 2px solid #A9A9A9;
  border-radius: 50%;
  padding:  0 !important;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  line-height: 20px;
}

/* CARROUSEL */
.carousel__rewards-brands {
  width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 22%;
}
.rewards-brands__viewport {
  overflow: hidden;
}
.rewards-brands__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
}
.rewards-brands__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.rewards-brands__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.rewards-brands__controls {
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
}
.rewards-brands__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.rewards-brands__buttons .embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.rewards-brands__buttons .embla__button:disabled {
  color: var(--detail-high-contrast);
}
.rewards-brands__buttons .embla__button__svg {
  width: 35%;
  height: 35%;
}
.rewards-brands__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.rewards-brands__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.rewards-brands__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.rewards-brands__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

@media (max-width: 1200px) {
  .carousel__rewards-brands {
    --slide-size: 30%;
  }

}

@media (max-width: 1200px) {
  .carousel__rewards-brands {
    --slide-size: 40%;
  }
}

@media (max-width: 767px) {
  .brands-title {
    margin-top: 40px;
  }
  .rewards-brands {
    height: 410px;
    padding: 0;
  }

  .carousel__rewards-brands {
    --slide-size: 100%;
  }

}
