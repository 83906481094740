.online-testimonials {
  padding: 68px 85px 80px 85px;
  height: max-content;
}

.testimonials__cards {
  margin-bottom: 30px;
}

.single__card {
  width: 360px;
  min-height: 440px;
  text-align: left;
  background-color: #c6c6c6;
  padding: 40px 40px 20px;
  border-radius: 10px;
  position: relative;
}

.card__profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile__name {
  display: block;
  font-size: 15px;
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
}

.profile__city {
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
  font-size: .75rem;
}

.testimonials__cards .card__content {
  font-weight: 400 !important;
  margin: 30px 0;
  font-family: "Futura",Sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: var(--dark-secondary);
  height: max-content;
}

.cards__second-card {
  height: 460px;
}

.testimonials__btn {
  height: 47px;
  padding: 0 25px;
  font-family: "Commissioner",Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark-secondary);
  background-color: #A4305500;
  transition-duration: .3s;
  border-style: solid;
  border-width: 1px;
  border-color: var(--dark-secondary);
}

@media(max-width: 768px) {
  .online-testimonials {
    padding: 50px 30px;
    height: 820px;
  }

  .testimonials__cards {
    display: none !important;
  }

}