.online-favorites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 650px;
  height: max-content;
  padding: 60px 50px;
}
.favorites__background {
  width: 100%;
  height: 1300px;
  z-index: -50;
  background-color: transparent;
  position: absolute;
  top: -40px;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;

  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
  transition-duration: 1s;
}

.favorites__title {
  width: 550px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 35px;
}

.favorites__desc {
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: #fff;
  margin-bottom: 5px !important;
  font-family: "Futura", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.favorites__btn {
  margin: 0 auto !important;
  height: 47px;
  font-family: "Commissioner", Sans-serif;
  font-weight: 800;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  transition-duration: 0.8s;
  fill: white;
  background-color: #a43055;
  border-radius: 32px !important;
  border-style: solid;
  border-width: 1px;
  padding: 15px 25px !important;
  line-height: 15px;
  border-color: white;
}

.favorites__btn:hover {
  background-color: var(--blue);
  color: white;
  border-color: white;
}


@media(max-width: 768px) {
  .online-favorites {
    height: 490px;
    padding: 50px 30px;
  }

  .favorites__title {
    width: 100%;
    text-align: center;
  }

  .favorites__desc {
    font-size: 16px;
    line-height: 1.3em;
  }

  .favorites__btn {
    width: 100%;
  }
}