.favorites-carousel {
  max-width: 100%;
  --slide-height: 19rem;
  --slide-spacing: 30px;
  --slide-size: 20%;
}
.favorites-carousel__viewport {
  margin-top: 45px;
  margin-bottom: 30px;
  overflow: hidden;
}
.favorites-carousel__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.favorites-carousel__slide {
  width: 270px;
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 300px;
  padding-left: var(--slide-spacing);
}

.favorites-carousel__slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.favorites-carousel__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
.favorites-carousel__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.favorites-carousel__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.favorites-carousel__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  margin: 0 6px;
  background-color: rgba(29, 29, 27, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.favorites-carousel__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.favorites-carousel__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: rgba(29, 29, 27, 1);
}

@media(max-width: 768px) {
  .favorites-carousel__slide {
    width: 170px;
    min-width: 170px;
  }
}
