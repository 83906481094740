.testimonials-carousel {
  display: block;
  margin: auto;
  max-width: 1080px;
  margin-bottom: 50px;
  --testimonials-slide-height: 19rem;
  --testimonials-slide-spacing: 1rem;
  --testimonials-slide-size: 360px;
}
.testimonials-carousel__viewport {
  overflow: hidden;
}
.testimonials-carousel__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--testimonials-slide-spacing) * -1);
}
.testimonials-carousel__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--testimonials-slide-size);
  padding-left: var(--testimonials-slide-spacing);
}

.testimonials-carousel__card {
  width: 100%;
  min-height: 440px;
  height: max-content;
  text-align: left;
  background-color: #c6c6c6;
  padding: 40px 40px 20px;
  border-radius: 10px;
  position: relative;
}

.testimonials-carousel__card__profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile__name {
  display: block;
  font-size: 15px;
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
}

.profile__city {
  color: var(--dark-secondary);
  font-family: "Futura",Sans-serif;
  font-size: .75rem;
}

.testimonials-carousel__card__content {
  font-weight: 400 !important;
  margin: 30px 0;
  font-family: "Futura",Sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: var(--dark-secondary);
  height: max-content;
}

.testimonials-carousel__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--testimonials-slide-height);
  user-select: none;
}
.testimonials-carousel__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.testimonials-carousel__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.testimonials-carousel__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  margin: 0 6px;
  background-color: rgba(29, 29, 27, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.testimonials-carousel__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.testimonials-carousel__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: rgba(29, 29, 27, 1);
}

@media(max-width: 1240px) {
  
  .testimonials-carousel {
    width: 100%;
  }

  .testimonials-carousel__slide {
    flex: 0 0 50%;
  }
  
}

@media(max-width: 600px) {
  .testimonials-carousel__slide {
    flex: 0 0 100%;
  }
}
