@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,800;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&display=swap');
body {
  overflow-x: hidden;
  max-width: 100vw;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900 !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 900 !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.btn {
  border-radius: 10px;
}

.btn-favorite {
  border-radius: 70px;
  position: absolute;  
  padding: 6px;  
  height: 35px;
  right: 20px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.btn-whatsapp {
  position: fixed;
  height: 50px;
  width: 120px;
  font-size: 24px;
  border-radius: 100px;
  bottom: 15px;
  left: 15px;
}

.class-type-home {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 0px;
}

.class-type-title {
  min-height: 100px;
}

.btn-whatsapp-text {
  font-size: 18px;
  top: -10px;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.btn-container {
  width: 103.2% !important;
}

.bg-spinning {
  background-image: url("https://i.blogs.es/a82e3b/istock-1134717663-1-/450_1000.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.tbm-card {
  background-color: #0a121f;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.schedule-img {
  margin-top: 32px;
  max-width: 400px;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.paquete-thumbnail {
  max-height: 300px;
  object-fit: cover;
  height: 100%;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.top-arrow {
  height: 25px;
}

.top-arrow:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f0f0f0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}

.schedule-class {
    width: 100%;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #000;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 350px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-container {
  width: 170%;
}

.latest-badge {
  max-width: 180px;
  position: absolute;
  top: 15px;
  width: 100%;
}

.card {
  padding: 24px;
  border-radius: 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  transition: all 0.25s;
}

.card.disabled:hover {
  box-shadow: none !important;  
  transform: none;
  cursor: not-allowed;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  transform: scale(1.05);
}

.no-scale:hover {
  transform: none !important;
}

.payment-method {
  max-width: 75px;
}

#paypal-button {
  max-height: 55px;
  overflow: hidden;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 35px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

.about-tbm {
  min-height: 500px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.coach-thumbnail {
  object-fit: cover;
}

.closed {
  height: 0px;
}

.nav-item {
  height: 70px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.25s;
  margin-top: -4px;
  cursor: pointer;
}

.progress {
  box-shadow: inset 2px 3px 15px -3px rgba(0,0,0, 0.5);
}

.progress-bar {
  box-shadow: 
    3px 5px 11px -3px rgba(0,0,0, 0.3),
    4px 6px 12px -3px rgba(0,0,0, 0.3);
    background: rgb(153,180,210);
    background: linear-gradient(0deg, rgba(153,180,210,1) 48%, rgba(192,222,255,1) 100%);
}

.progress-bar-percentage {
  color: rgb(239,238,234); 

}
.progress-bar-percentage::before {
  filter: drop-shadow(3px 4px 2px rgba(0,0,0,0.2));
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
} 

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.app-link:hover .home-img {
  transform: scale(1.1);
}

.app-link {
  height: 100vh;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.app-link-overlay {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.4;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  width: 100%;  
}

.app-link-logo  {
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 100px);
  width: 300px;
  margin: auto;
  display: block;
  z-index: 10;
}

.app-link-title {
  color: white;
  text-align: center;
  position: absolute;
  font-size: 3.5rem;
  width: 100%;
  top: 45%;
  z-index: 10;
}

.navbar {
  padding: 0px;
}

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-img {
  max-height: 500px;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.program-video-card {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  margin-top: 16px;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  background: #fff;
  padding-bottom: 0px;
  min-height: calc(100vh - 65px - 73px);
  
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.modal-lg {
  max-width: 800px;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  min-height: calc(100vh - 65px - 73px);
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.schedule-class {
  border-radius: 10px;
  width: 200px;
  text-align: center;
  border: 1px solid #000;
  float: left;
}

.schedule-classes-container {
  min-height: 300px;
  height: auto;
  max-width: 300%;
}

.profile-image {
  margin: auto;
  width: 150px;
  height: 150px;
  display: block;
  object-fit: cover;
  border-radius: 300px;
}

.profile-thumbnail {
  border-radius: 100px;
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.logo-thumbnail {
  width: 75px;
}

.icon {
  width: 35px;
  height: 35px;
}

.thumbnail {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.thumbnail-sm {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.program-icon {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.user-icon {
  padding: 20px 22px;
}

.user-icon-sm {
  padding: 12px 18px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.dropdown-item {
  white-space: wrap;
  font-size: 14px;
}

.badge-props {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 15px;
}

.props-list {
  position: absolute;
  left: 15px;
  top: 10px;
}

.video-thumbnail-container {
  position: relative;  
  max-height: 200px;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

img.video-thumbnail {
  position: absolute;  
  object-fit: cover;
  height: 200px;
  width: 100%;
  left: 0;
  top: 0;
}

.span-completed {
  padding-left: 10px !important;
  border-radius: 70px;
  position: absolute;  
  padding: 8px;  
  height: 35px;
  right: 50px;
  width: 35px;
  z-index: 1;
  top: 10px;
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video-date {
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 15px;
}

.card-video img {
  border-radius: 15px 15px 0px 0px;
}

.card-video p {
  font-size: 14px;
}

.card-video p i {
  font-size: 18px;
}

.card-video h3 {
  font-size: 18px;
}

.panel-title {
  padding-bottom: 16px;
  padding-top: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-video-bg {
  height: 100%;
  min-height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.latest-card {
  max-height: 320px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.dropdown-menu {
  margin-left: -45px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}


.logo-navbar {
  max-width: 50px;
}

.logo-login {
  max-width: 100px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.mobile {
  border-radius: 10px;
}

.offcanvas {
  visibility: visible !important;
  z-index: 15 !important;
}

.offcanvas-header {
  margin-top: 16px;
  margin-bottom: 32px;
}

.offcanvas-end {
  width: 50vw;
}

.show {
  display: block;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.mw-1600 {
  max-width: 1500px;
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  z-index: 1;
  filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 10px;
}

.br-25 {
  border-radius: 25px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.bg-vertical {
  background-image: url("/src/assets/images/banner_movil.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.show-mobile {
  display: none;
}

.main-cta-cont {
  z-index: 10;
}

.main-cta-cont h1, .main-cta-cont p, .text-shadow {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
}


.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.hover-shadow:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.panel-home-item {
  height: 65vh;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

td {
  min-width: 125px;
}

.mapa-lugares tr, .mapa-lugares td {
  border-width: 0px;
}

.mapa-lugares td {
  text-align: center;
  min-width: auto;
  width: 50px;
  padding: 2px !important;
}

.mapa-lugares tr.text-end td {
  text-align: end;
}

.mapa-lugares tr.text-start td {
  text-align: start;
}

.mapa-lugares .jump td.text-start {
  padding-left: 15px !important;
}

.mapa-lugares tr {
  height: 50px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .program-name {
    font-size: 16px;
  }
  .mapa-lugares .btn {
    padding: 0.25rem 0.5rem !important;
    font-size: 12px !important;
  } 
}

.table> :not(caption)>*>* {
  padding: 10px 12px;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: inherit;
}

th {
  min-width: 200px;
}

.online-bg {
  background-image: url("/src/assets/images/bg-tbm-online.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

.main-cta-btn {
  font-size: 20px;
  padding: 10px 16px;
}

.presencial-bg {
  background-image: url("/src/assets/images/bg-tbm-presencial.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.schedule-class-cont {
  padding: 15px;
}

.quote {
  position: absolute;
  height: 350px;
  top: -200px;
}

.page-content {
  min-height: 85vh;
}

.planner-calendar-wrapper {  
  margin-left: -12px;
  padding: 12px;  
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
}

.bg-loading {
  background-image: url("/src/assets/images/bg-tbm-online.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-image: url("/src/assets/images/bg-tbm-online.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

#estrenos { 
  min-height: 400px;
  position: relative;
  overflow: hidden;
}

#workout-planner, #workout-planner .bg-image {
  height: 100vh
}

#planner-calendar .schedule-col {
  margin-top: 0px;
  width: calc(100% / 8);
}

#close-modal {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 5px;
}

.video-info {
  height: 80vh;
}

.z-index-1000 {
  z-index: 1000;
}

#main-landing .row {
  height: 100vh;
}

#muscles-diagram {
  max-width: 100%;
}

@media (max-width: 1200px) {
  #video, .home-video {
    height: 610px;
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  .video-info {
    height: auto;
  }
  #video {
    height: 80vh;
  }
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
  .panel-mobile-menu {
    display: block;
  }
  #panel-navbar {
    display: none;
  }
}

@media (min-width: 767px) {

  .resp-calendar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-link {
    height: 25vh;
  }
  .app-link-title {
    font-size: 28px;
  }
  .page-content {
    min-height: 75vh;
  }
  .logo-thumbnail {
    width: 50px;
  }
  .main-panel {
    min-height: auto;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .app-link-logo  {
    position: absolute;
    left: calc(50% - 150px);
    top: calc(70% - 100px);
    width: 300px;
    margin: auto;
    display: block;
    z-index: 10;
  }
  .home-img {
    height: 50%;
  }
  .content {
    margin-left: 0px;
    margin-right: 0px;
    min-height: auto;
  }
  .offcanvas-end {
    width: 90vw;
  }
  .latest-video-bg {
    min-height: 180px;
  }
  .latest-card {
    max-height: 100%;
  }
  .schedule-img {
    max-width: 100%;
  }
  .schedule-day {
    margin-bottom: 0px !important;
    width: 100%;
  }
  .schedule-class {
    width: 50%;
  }
  .mobile-left, .mobile-left.text-end {
    text-align: left !important;
  }
  .btn-reservaciones {
    padding-left: 0px;
  }
  .schedule {
    margin-top: 12px;
  }
  .schedule-class-cont {
    border: 0px !important;
    box-shadow: none !important;
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
  .main-cta-btn {
    font-size: 16px;
    padding: 8px 12px;
  }
  .main-cta-cont p {
    font-size: 18px;
  }
  .show-mobile {
    display: inherit;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  p.badge {
    font-size: 1.2rem !important;
  }
  .navbar-container {
    padding-right: 24px;
    padding-top: 0px;
  }
  .navbar {
    max-width: 100vw !important;
    overflow: hidden;
  }
  .mobile-center, .mobile-center * {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #video {
    background-image: url("/src/assets/images/banner_movil.jpeg");
  }
  #estrenos {
    height: auto;
  }
  #workout-planner {
    height: auto;
    min-height: 80vh;
  }
  #workout-planner .bg-image {
    height: 40vh;
  }
  #planner-calendar {
    min-width: 800px;
  }
  #planner-section {
    position: relative;
  }
  #planner-section .arrow-left {
    position: absolute;
    left: -30px;
    top: 25%;
  }
  #planner-section .arrow-right {
    position: absolute;
    right: -30px;
    top: 25%;
  }
}
