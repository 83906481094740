#landing__online {
  overflow-x: hidden;
  width: 100vw;
}
.online-transparent-btn {
  fill: white;
  background-color: #a4305500;
  transition-duration: 0.3s;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 32px !important;
}

.online-transparent-btn:hover {
  background-color: #a43055;
  color: white;
}

.online-title {
  color: white;
  font-family: "Futura", Sans-serif;
  font-size: 35px;
  font-weight: 900 !important;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -1px;
}

.online-subtitle {
  color: white;
  font-family: "Futura", Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-style: oblique;
}

@media (max-width: 768px) {
  .online-title {
    font-size: 22px;
  }

  .online-subtitle {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateY(-10px); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

@keyframes fadeInAndTranslate {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn .75s;
}

.animate-fade-in-and-translate {
  animation: fadeInAndTranslate .75s;
}