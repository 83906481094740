.online-filters {
  height: max-content;
  width: 100%;
  padding: 200px 50px 80px 50px;
}

.custom-ratio {
  aspect-ratio: 2 / 1; 

}


.filters__container {
  padding-top: 40px;
}

.filters__list {
  max-width: 535px;
}
.filters__item {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray);
  margin-top: 10px;
}

.filters__item-text {
  font-family: "Futura",Sans-serif;
  font-size: 18px;
  color: var(--dark-secondary);
  font-style: normal;
}

.filters__programas {
  width: 520px;
}

.filters__programas img {
  width: 100%;
  object-fit: cover;
}

.programa-overlay {
  opacity: 0;
  transition: opacity 1s ease; 
  width: calc(100% - calc(var(--bs-gutter-x)* .5)*2);
  left: calc(var(--bs-gutter-x)*.5);
  position: absolute;
  height: 100%;
  right: 0;
}

.programa-overlay:hover {
  opacity: 0.5;
}

.online-filters__carrousel {
  margin-top: 40px;
}

.online-filters__carrousel button {
  color: var(--dark-secondary);
  padding: 15px 25px;
  font-family: "Commissioner",Sans-serif;
  font-weight: 900;
  font-size: 15px !important;
  line-height: 15px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #A4305500;
  transition-duration: .3s;
  border-style: solid;
  border-width: 1px;
  border-color: var(--e-global-color-text);
}

@media(max-width: 1024px) {
  .filters__container {
    flex-direction: column;
    padding-top: 0;
  }

  .online-filters {
    padding: 180px 30px 30px 30px;
  }

  .filters__programas {
    width: 100%;
    margin-top: 40px;
  }
}


@media(max-width: 768px) {

  .online-filters {
    padding: 138px 30px 30px 30px;
  }

  .filters__container {
    flex-direction: column;
    padding-top: 0;
  }

  .filters__item-text {
    font-size: 16px;
  }

  .filters__programas {
    width: 100%;
    margin-top: 40px;
  }
}