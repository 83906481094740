.embla {
  margin: auto;
  --package-slide-height: 19rem;
  --package-slide-mobile-height: 19rem;
  --package-slide-spacing: 2rem;
  --package-slide-size: 400px;
  --package-mobile-slide-size: 100%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--package-slide-spacing) * -1);
}

.packages__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--package-slide-size);
  min-width: 0;
  padding-left: var(--package-slide-spacing);
}

.embla__slide__package {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 16px;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: var(--package-slide-height);
  user-select: none;
  border: 1px solid gray;
  height: 520px;
  padding: 0px;
}

.package-card__header {
  width: 100%;
  height: 160px;
  padding: 10px;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: rgba(103, 101, 93, 0.37);
}

.header__name {
  text-align: center;
  font-size: 36px;
  margin-bottom: 5px;
  line-height: 36px;
}

.header__expiration {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.package-card__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
}

.content__price {
  width: max-content;
  font-size: 40px;
  color: black;
  margin-bottom: 5px;
  line-height: 40px;
}

.content__subscription {
  min-height: 16px;
  margin-bottom: 20px !important;
  width: max-content;
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: 0px;
}

.content__subscription.mb-0 {
  margin-bottom: 8px !important;
}

.content__features {
  width: 100%;
}

.content__feature {
  text-transform: none !important;
  color: black;
  border-bottom: 1px solid black;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 5px !important;
}


.embla__type-name {
  font-size: 30px;
  color: black;
  font-weight: 900;
  line-height: 30px;
}

.embla__type-image {
  width: 100%;
  border-radius: 16px;
  height: 310px;
  min-height: 310px;
  object-fit: cover;
  margin-bottom: 10px;
}

.embla__type-description {
  font-size: 19px;
  font-stretch: ultra-condensed;
  line-height: 21px;
  font-weight: 500;
}

.embla__controls {
  height: 60px;
  display: flex;
  position: relative;
  padding-right: 85px;
  justify-content: center;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid gray;
  padding: 0;
  margin: 0;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: var(--primary);
}

.class-package__cta {
  font-size: 19px !important;
  height: 50px;
  align-self: flex-end;
}

@media(max-width: 450px) {
  .packages__slide {
    flex: 0 0 var(--package-mobile-slide-size);
  }

  .embla__slide__package {
    /* height: var(--package-slide-mobile-height); */
  }

  .class-package__cta {
    font-size: 15px !important;
    height: 40px;
  }

  .embla__controls {
    padding: 0;
    display: flex !important;
    margin-bottom: 1.8rem;
  }

  .embla__buttons {
    display: none !important;
  }

  .embla__dots {
    width: 100%;
    justify-content: center !important;
  }
}
