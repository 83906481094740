.online__metodo {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 600px;
  padding-top: 80px;
  padding-bottom: 250px;
  background: rgb(157, 51, 85);
  background: linear-gradient(
    23deg,
    rgba(157, 51, 85, 1) 26%,
    rgba(109, 134, 180, 1) 100%
  );
}

.online__metodo__overlay {
  width: 100%;
  height: 600px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background: rgb(255, 255, 255, 0.1);
}

.content__about {
  width: 660px;
}

.content__image {
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.content__image img {
  width: 70%;
}

.image__desc {
  color: white;
  font-family: "Futura", Sans-serif;
  font-size: 34px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.2em;
  position: absolute;
  bottom: 0;
}

.about__subtitle {
  color: white;
  font-family: "Futura", Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-style: oblique;
}

.about__title {
  color: white;
  font-family: "Futura", Sans-serif;
  font-size: 35px;
  font-weight: 900 !important;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -1px;
}

.about__desc {
  text-align: left;
  color: #fff;
  font-family: "Futura", Sans-serif;
  font-size: 20px;
  font-weight: 400 !important;
}

.about__btn {
  position: absolute;
  bottom: 170px;
  height: 47px;
  font-family: "Commissioner", Sans-serif;
  font-weight: 800;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  transition-duration: 0.8s;
  fill: white;
  background-color: #a43055;
  border-radius: 32px !important;
  border-style: solid;
  border-width: 1px;
  padding: 15px 25px !important;
  line-height: 15px;
  border-color: white;
}

.about__btn:hover {
  background-color: var(--blue);
  color: white;
  border-color: white;
}

.metodo__tv-image {
  position: absolute;
  width: 500px;
  height: auto;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -170px;
}


@media(max-width: 1024px) {
  .online__metodo {
    padding-top: 40px;
    padding-bottom: 250px;
  }

  .metodo__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content__about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    width: 100%;
  }

  .online__metodo .about__subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  .online__metodo .about__title {
    font-size: 35px;
    text-align: center;

  }

  .online__metodo .about__desc {
    font-size: 20px;
    line-height: 1.1em;
    width: 100%;
  }

  .online__metodo .about__btn {
    position: relative;
    margin: 0;
    bottom: unset;
    margin-top: 20px;
  }

  .content__image img{
    width: 315px;
    margin-top: 20px;
  }

  .image__desc {
    font-size: 19px;
    width: 315px;
    margin-bottom: 150px;
  }

  .metodo__tv-image {
    width: 320px;
    margin-top: 0;
    bottom: -150px;
  }

}


@media(max-width: 425px) {
  .online__metodo {
    padding: 0 20px;
    padding-top: 40px;
    padding-bottom: 250px;
  }

  .metodo__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  

  .content__about {
    width: 100%;
  }

  .online__metodo .about__subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  .online__metodo .about__title {
    font-size: 19px;
    text-align: center;

  }

  .online__metodo .about__desc {
    font-size: 16px;
    line-height: 1.1em;
    width: 290px;
    margin: 0 auto;
    text-align: center;
  }

  .online__metodo .about__btn {
    position: relative;
    margin: 0;
    bottom: unset;
    margin-top: 20px;
    width: 100%;
  }

  .content__image img{
    width: 315px;
    margin-top: 20px;
    
  }

  .image__desc {
    font-size: 19px;
    width: 315px;
    margin-bottom: 150px;
  }

  .metodo__tv-image {
    width: 320px;
    margin-top: 0;
    bottom: -120px;
  }

}
