#navbar-landing-online {
  padding: 20px 50px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

#navbar-landing-online .navbar-toggler {
  background-color: transparent !important;
}

#navbar-landing-online .navbar-container .navbar-nav .nav-item:hover {
  background-color: unset !important;
  border: unset !important;
  color: var(--primary) !important;
}

#navbar-landing-online .navbar-container .navbar-nav .nav-item .nav-link {
  font-size: 14px;
  color: white;
  font-family: "Futura", Sans-serif;
  padding: 0 !important;
}


#navbar-landing-online .navbar-container .navbar-nav .nav-item-active {
    color: var(--primary) !important;
    background-color: unset !important;
    border: unset !important;
}


#navbar-landing-online .navbar-container .drawer-menu .navbar-nav .nav-item a{
  font-size: 17px !important;
  font-family: "Futura Std Condensed",Sans-serif;
  padding: 15px !important;
  width: 100%;
  color: #1d1d1b;
  font-weight: 900;
  text-transform: uppercase !important;
}



#navbar-landing-online .nav-link:hover {
  color: var(--primary) !important;
}

.container__content-container .navbar-nav .nav-item .nav-link {
  font-size: 18px;
  color: white;
}

.online-navbar__login-btn {
  color: white;
  border: none;
  background-image: linear-gradient(90deg, var(--primary) 0%, var(--blue) 100%);
  border-radius: 100px;
  padding: 12px 24px;
  font-family: "Futura", Sans-serif;
  font-size: 15px;
  font-weight: 900 !important;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.3px;
  background-color: transparent;
}


@media(max-width: 768px) {
  #navbar-landing-online {
    padding: 20px 20px;
  }
  #navbar-landing-online .navbar-container .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
  .online-navbar__login-btn {
    padding: 10px 10px;
    height: 37px;
    width: 110px;
    font-size: 12px;
  }
  #navbar-landing-online .logo-navbar {
    width: 115px;
    min-width: 115px;
  }
}