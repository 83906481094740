.features-carousel {
  max-width: 48rem;
  margin: auto;
  --features-slide-height: 19rem;
  --features-slide-spacing: 1rem;
  --features-slide-size: 100%;
}
.features-carousel__viewport {
  overflow: hidden;
}
.features-carousel__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--features-slide-spacing) * -1);
}
.features-carousel__slide {
  width: 110px;
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--features-slide-size);
  min-width: 110px;
  padding-left: var(--features-slide-spacing);
}

.features-carousel__slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.8rem;
}
.features-carousel__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--features-slide-height);
  user-select: none;
}
.features-carousel__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.features-carousel__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.features-carousel__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  margin: 0 6px;
  background-color: rgba(29, 29, 27, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.features-carousel__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.features-carousel__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
  background-color: rgba(29, 29, 27, 1);
}

@media(max-width: 768px) {
  .features-carousel {
    margin-right: 0;
    width: calc(100% - 360px);
  }
}

@media(max-width: 600px) {
  .features-carousel {
    max-width: 315px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 0 50px;
    width: 100%
  }
}
