.online__class-types {
  height: 810px;
  padding: 80px !important;
}

.online__class-types--modal {
  display: flex;
  width: 640px;
  height: auto;
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  z-index: 1200;
  transform: translate(-50%, -50%);
}

.online__class-types--modal img {
  width: 280px;
  height: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.class-types--modal__backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  z-index: 1100;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100vw;
}

.class-types--modal__content {
  padding: 30px;
}


.class-types--modal__content h2 {
  color: var(--dark-secondary);
  font-family: "Futura", Sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1em;
}

.modal__content--benefits {
  font-size: 16px;
  color: var(--dark-secondary);
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.online__class-types--modal span {
  color: var(--dark-secondary);
  font-weight: 400;
  font-family: "Futura",Sans-serif;
  line-height: 1.1em;
  padding-bottom: 7px;
  margin-top: 6px;
}

.modal__content--benefit-border {
  border-bottom: 1px solid var(--light);
}

.class-types--modal__content button {
  margin-top: 20px;
  font-size: 15px;
  line-height: 1;
  width: 100%;
  font-family: "Commissioner",Sans-serif;
  font-weight: 900 !important;
  text-transform: uppercase;
  text-decoration: none;
  fill: var(--e-global-color-text);
  color: var(--dark-secondary);
  background-color: #A4305500;
  transition-duration: .3s;
  border-style: solid;
  border-radius: 32px;
  border-width: 1px;
  border-color: var(--dark-secondary);
  padding: 10px;
}


.class-types__title {
  font-family: "Futura", Sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.1em;
  letter-spacing: -1px;
  width: 570px;
  color: #1d1d1b;
  text-align: center;
  margin: 0 auto;
}

.class-types__desc {
  text-align: center;
  color: #1d1d1b;
}

@media(max-width: 768px) {
  .online__class-types {
    padding: 30px !important;
    height: 650px;
  }

  .class-types__title {
    font-size: 20px;
    width: 100%;
  }

  .class-types__desc {
    font-size: 16px;
    line-height: 1.3em;
  }

  .online__class-types--modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: fixed;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    z-index: 1200;
    transform: translate(-50%, -50%);
  }
  
  .online__class-types--modal img {
    width: 100%;
    height: auto;
    max-height: 245px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

}