#landing__online h1,
#landing__online h2,
#landing__online h6
 {
  font-family: 'Futura Std Condensed';
}


.online-footer {
  height: 445px;
  background-color: transparent;
  position: relative;
  padding: 60px 0;
}

.online-footer__background {
  width: 100%;
  height: 820px;
  z-index: -50;
  background-color: transparent;
  position: absolute;
  top: -120px;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;

  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
  transition-duration: 1s;
}

.footer__desc {
  max-width: 885px;
  margin: 0 auto;
  max-width: 884px;
  text-align: center;
  color: white;
  font-family: "Futura",Sans-serif;
  font-size: 19px;
  line-height: 1.3em;
}

.footer__btn {
  height: 47px;
  font-family: "Commissioner", Sans-serif;
  font-weight: 800;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  transition-duration: 0.8s;
  fill: white;
  background-color: #a43055;
  border-radius: 32px !important;
  border-style: solid;
  border-width: 1px;
  padding: 15px 25px !important;
  line-height: 15px;
  border-color: white;
}

.footer__btn:hover {
  background-color: var(--blue);
  color: white;
  border-color: white;
} 

.footer__brand {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 35px;
  background-color: #67655d;
  display: flex;
  align-items: center;
  color: white;
  font-family: "Futura Std Condensed",Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
}

@media(max-width: 768px) {
  .online-footer {
    padding: 50px 30px;
    height: 540px;
  }

  .footer__desc {
    font-size: 16px;
  }

  .footer__btn {
    width: 100%;
  }

  .footer__brand{
    font-size: 12px;
  }
}